import './bootstrap';
import '../css/app.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './store';
import ErrorBoundary from "@/Layouts/ErrorBoundary";

const appName = import.meta.env.VITE_APP_NAME || 'Virtual ISP Billing Advance';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);
        root.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <ToastContainer />
                    <App {...props} />
                </Provider>
            </ErrorBoundary>
        );
    },
    progress: false
    // progress: {
    //     color: '#1d72b8',  // Change the color to blue
    //     showSpinner: false,  // Show a spinner next to the progress bar
    //     delay: 200,         // Delay before showing the progress bar (in milliseconds)
    //     includeCSS: false,   // Include the default Inertia progress bar CSS
    // },
});
//
// App.jsx
// import './bootstrap';
// import '../css/app.css';
// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import { createInertiaApp } from '@inertiajs/react';
// import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Provider } from 'react-redux';
// import store from './store';
// import ErrorBoundary from '@/Layouts/ErrorBoundary';
// import GlobalStyle from '@/Layouts/GlobalStyle'; // Import GlobalStyle
// import { LayoutProvider } from './Layouts/LayoutContext'; // Import Context
//
// const appName = import.meta.env.VITE_APP_NAME || 'Virtual ISP Billing Advance';
//
// createInertiaApp({
//     title: (title) => `${title} - ${appName}`,
//     resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
//     setup({ el, App, props }) {
//         const root = createRoot(el);
//         const { auth} = props.initialPage.props;
//         const pageLink = props.initialPage.url
//         root.render(
//             <LayoutProvider auth={auth} pageLink={pageLink}>
//                 <ErrorBoundary>
//                     <Provider store={store}>
//                         <ToastContainer />
//                         <GlobalStyle />
//                         <App {...props} />
//                     </Provider>
//                 </ErrorBoundary>
//             </LayoutProvider>
//         );
//     },
//     progress: false,
// });
