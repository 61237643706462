import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

// Initial state
const initialState = {
    isLoading: false,
    mainMenus: [],
    error: null,
};

// Thunk to get theme settings
export const getMenuData = createAsyncThunk(
    "mainMenu/getAllData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get('/admin/menu_list');
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response && error.response.data ? error.response.data : error.message
            );
        }
    }
);

// Slice
const MenuSettingSlice = createSlice({
    name: "mainMenu",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getMenuData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getMenuData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.mainMenus = payload;
                state.error = null;
            })
            .addCase(getMenuData.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            });
    }
});

export const { } = MenuSettingSlice.actions;
export default MenuSettingSlice.reducer;
