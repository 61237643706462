
import { configureStore } from '@reduxjs/toolkit';
import themeSettingSlice from "@/Pages/reducers/themeSettingSlice";
import MenuSettingSlice from "@/Pages/reducers/MenuSettingSlice";
import billingConditionSlice from "@/Pages/reducers/billingConditionSlice";

const store = configureStore({
    reducer: {
        themeSettings: themeSettingSlice,
        mainMenu: MenuSettingSlice,
        billingCondition: billingConditionSlice,
    }
})
export default store;

