import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

// Initial state
const initialState = {
    isLoading: false,
    billConditions: [],
    error: null,
};

// Fetch conditions (GET request)
export const getConditionData = createAsyncThunk("billingCondition/getConditionData", async () => {
    try {
        const response = await axios.get('/admin/billing_condition');
        return response.data;
    } catch (error) {
        throw error;
    }
});


// Update condition (POST request for update)
export const updateConditionData = createAsyncThunk("billingCondition/updateConditionData", async (data, { rejectWithValue }) => {
    try {
        // console.log("submit data",data);
        const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
        const headers = { 'X-CSRF-TOKEN': csrfToken };
        const response = await axios.post(`/admin/billing/condition/${data.id}`, data, { headers });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// Slice
const billingConditionSlice = createSlice({
    name: "billingCondition",
    initialState,
    extraReducers: (builder) => {
        builder
        // Fetching conditions
            .addCase(getConditionData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getConditionData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.billConditions = payload;
                state.error = null;
            })
            .addCase(getConditionData.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            })

            // Updating condition
            .addCase(updateConditionData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateConditionData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.billConditions = payload;
                state.error = null;
            })
            .addCase(updateConditionData.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            });
    }
});

export const { } = billingConditionSlice.actions;
export default billingConditionSlice.reducer;
