import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

// Initial state
const initialState = {
    isLoading: false,
    themeSettings: [],  // Adjust based on actual structure (array or object)
    error: null,
};

// Thunk to get theme settings
export const getThemeData = createAsyncThunk("themeSettings/getAllData", async () => {
    try {
        const response = await axios.get('/theme-setting');
        return response.data;  // Ensure this is the format you expect
    } catch (error) {
        throw error;
    }
});

export const postData = createAsyncThunk(
    "themeSettings/postData",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('/theme_setting', data);  // URL should match Laravel route
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue(error.message);
            }
        }
    }
);


// Slice
const themeSettingSlice = createSlice({
    name: "themeSettings",
    initialState,
    reducers: {
        getSingleData(state, { payload }) {
            const { id } = payload;
            state.metaData = state.themeSettings.find((type) => type.id === id) || {};
        },
    },
    extraReducers: (builder) => {
        builder
        // Handling get theme data
            .addCase(getThemeData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getThemeData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.themeSettings = payload;  // Ensure payload is in the expected format
                state.error = null;
            })
            .addCase(getThemeData.rejected, (state, { error }) => {
                state.isLoading = false;
                state.error = error.message;
            })
            // Handling post theme data
            .addCase(postData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(postData.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.themeSettings = payload;  // Adjust if necessary
                state.error = null;
            })
            .addCase(postData.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload;
            });
    }
});

export const { getSingleData } = themeSettingSlice.actions;
export default themeSettingSlice.reducer;
